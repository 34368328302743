import { AppBar, Toolbar, Typography, Link } from '@mui/material';

export const Header = () => {
  const base = process.env.PUBLIC_URL || '';
  const home = base + '/#/';
  return (
    <AppBar position="static" color="transparent" sx={{ marginBottom: 4 }}>
      <Toolbar>
        <Link href={home} color="inherit" underline="none">
          <Typography variant="h6" component="div">
            Datagate
          </Typography>
        </Link>
      </Toolbar>
    </AppBar>
  );
};
